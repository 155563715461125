import React from "react"
import "../style/nri.css"
import Layout from "../components/layout"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { render } from "react-dom";

class mailHelper extends React.Component {
    constructor(){
        super()
        this.getCookie = this.getCookie.bind(this);
      }
    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    } 
    nriMail = () => {
        var name = document.getElementById("nri_name").value.trim();
        var phone = document.getElementById("nri_phone").value.trim();
        var email = document.getElementById("nri_email").value.trim();

        // UTM DATA
        // Populate Cookies data
        var utmSource = this.getCookie("sourcey");
        var utmMedium = this.getCookie("mediumy");
        var utmCampaign = this.getCookie("campaigny");
        var utmTerm = this.getCookie("termy");
        var utmContent = this.getCookie("contenty");


        // Email Format
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (name == "") {
            alert("Enter you Name");
        }

        else if (reg.test(email) == false) {
            alert("Enter a valid Email");
        }

        else if (phone.toString().length != 10) {
            alert("Enter a valid Phone number");
        }

        else {
            var HttpClient = function () {
                this.get = function (aUrl, aCallback) {
                    var anHttpRequest = new XMLHttpRequest();
                    anHttpRequest.onreadystatechange = function () {
                        if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
                            aCallback(anHttpRequest.responseText);
                    }
                    anHttpRequest.open("GET", aUrl, true);
                    anHttpRequest.send(null);
                }
            }
            var theurl = 'https://script.google.com/macros/s/AKfycbxTL4N7QO1DlSpPyH01Z9Fldg7d_PO1ji6Y9mSM4j-etoPETnP1MlGMKQ/exec?name=' + name + "&email=" + email + "&phone=" + phone + "&utmSource=" + utmSource + "&utmMedium=" + utmMedium + "&utmContent=" + utmContent + "&utmTerm=" + utmTerm + "&utmCampaign=" + utmCampaign;
            var client = new HttpClient();
            client.get(theurl, function (response) {
                var response1 = JSON.parse(response);
                //document.getElementById("print").innerHTML = response1.status ; 
            });  
            window.location.replace("/nri_brochure_thankyou");
        }
    }
    render() {
        return (
            <Layout>
                <br /><br /><br /><br />
                <div className="container p-5">
                    <div class="row">
                        <Tabs>
                            <TabList>
                                <Tab><span className="t-m">ABOUT NRI</span></Tab>
                                <Tab><span className="t-m">NRI DOCUMENTS</span></Tab>
                                <Tab><span className="t-m">NRI'S FAQ'S</span></Tab>
                                <Tab><span className="t-m">DOWNLOAD BROCHURE</span></Tab>
                            </TabList>
                            <hr style={{ borderTop: "solid 1px #aaa" }} />
                            <TabPanel>
                                <div className="row tab-1 p-4 text-justify lh-2">
                                    <p>Non-Resident Indians (NRIs), under RBI guidelines, can purchase certain kinds of properties, though other forms may require special permissions. Thus every NRI, interested in buying property in India, must be aware of the provisions related to purchase or ownership of immovable property in India under Foreign Exchange Management Act (FEMA). Persons of Indian Origin (PIOs) and NRIs are always treated the same when it comes to investing in real estate. </p>
                                    <p>The Reserve Bank of India (RBI) has given general permission to NRIs for any residential or commercial property in India. They do not need to seek any special permission from RBI or send any intimation in this regard. NRIs can purchase any number of commercial or residential properties in India. The income tax laws also favour NRIs. In case the NRI is not able to come to India, he/she has the power to give anyone power of attorney who will execute the deal likewise.</p>
                                    <p>However, the RBI does not allow NRIs to purchase agricultural land or plantation property in India. Under existing regulations, NRIs can’t also purchase farmhouses in India. To purchase farmhouses, the NRI has to take special permission, which will be considered on a case-to-case basis. It is possible for an NRI to purchase property jointly with another NRI. However, any person not authorised to invest in property in India or a resident Indian, cannot become a joint holder irrespective of his/her contribution towards the purchase.</p>
                                    <p>If a person who owns a property in India becomes an NRI, he can continue holding the property in his name in India. Interestingly, an NRI will be allowed to hold agricultural land, farmhouse and plantation property if he purchased them before becoming an NRI. He can also let out the property without considering the fact when it was acquired. It is possible to remit the rent received from such properties after appropriate Indian taxes have been paid on such rent. NRIs can also gift or sell any immovable property to a person residing in India.</p>
                                    <p>The NRI can even gift or transfer any property to any NRI, provided it’s not a farmhouse, plantation property or agricultural property.</p>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row tab-1 p-4 text-justify lh-2" >
                                    <p>Non Resident Indians (NRIs) can avail home loans for the purchase of flats, bungalows and villas in India. They need copies of valid passport, statements of non-resident external (NRE) or non-resident ordinary (NRO) accounts, salary certificate, last six months bank account statement/passbook, work experience certificate and a valid job contract or work permit. A person must be employed for at least two years. Businessmen and self-employed individuals need to show proof of income.</p>
                                    <p>If an NRI is availing a home loan, he/she needs to attest the salary certificate from the embassy in case the salary is not credited to a bank. The person must also provide a local address proof and provide power of attorney (PoA) to someone in India. Details of permanent address in India are also necessary. A completed home loan application form is a must and the English translation of the contract must be duly attested by consulate, employer, embassy or the Indian bank’s foreign branch, in case the contract is in some other language.</p>
                                    <p>Other documents that the NRI would need to purchase property in India are agreement for sale/sale deed, copy of approvals for proposed construction, purchase or extension/approved building plans and a no objection certificate (NOC). For purchase or construction of house, the documents he would require are non-encumbrance certificate on the property (for 13 years), original title deed, land tax receipt and possession certificate. In case, the NRI wants to authorise a third party to avail loan instalments, complete mortgage formalities and execute documents, a notarised power of attorney is a must.</p>
                                    <p>The documents required can be divided under headings such as Personal Details Documents, Financial Details Documents for Salaried Individuals, Financial Details Documents for Self Employed Individuals and NRI Personal Guarantor documents. Different banks have different formats for the same. Here we have mentioned them in bullet points of your ease.</p>
                                    <p><b>Financial Details Documents for Salaried Individuals</b></p>
                                    <p><ul>
                                        <li>Copy of salary certificate mentioning the current salary earned, designation and date of joining or copy of current appointment letter</li>
                                        <li>Copy of previous appointment letters</li>
                                        <li>Last four months’ salary slips in case of variable salary, or last three months’ salary slip in case of fixed salar</li>
                                        <li>Six months bank statement clearly indicating credited salaries</li>
                                    </ul></p>
                                    <p>Certain other details may also be asked for such as copy of PAN Card for the first applicant OR Form 60, Cheque/Draft in INR for fees with date in dd/mm/yyyy format, valid visa copies for all applicants and one passport size latest photograph of each applicant (includes co-applicant) with signature across.</p>
                                    <p><b>NRI Personal Guarantor Documents</b>
                                        <ul>
                                            <li>NRI Guarantor’s passport and visa copies</li>
                                            <li>Income documents of guarantor</li>
                                            <li>Current overseas address verification proof, such as, driving license, bank statement, utility bill, latest insurance premium receipt showcasing overseas address</li>
                                            <li>Rapid increase in start-ups</li>
                                            <li>Medical Tourism</li>
                                        </ul></p>
                                    <p><b>Financial Details Documents for Self Employed Individuals</b>
                                        <ul>
                                            <li>Copy of incorporation of business</li>
                                            <li>Copy of income proof</li>
                                            <li>Middle East: Last 2 years audited/C.A certified P&L accounts</li>
                                            <li>US & Canada: Last 2 years CPA compiled/reviewed/audited P&L accounts</li>
                                            <li>Others: Last 2 years C.A. or equivalent certified/audited P&L accounts</li>
                                            <li>Copy of business profile and office address proof</li>
                                            <li>Copy of last six months’ bank statements in company’s name & individual’s name</li>
                                            <li>MOA & AOA of the company for private limited companies</li>
                                            <li>Partnership deed for partnership firm</li>
                                        </ul></p>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row tab-1 p-4 text-justify lh-2" id="tb3">
                                    <div className="col-md-12 text-center">
                                        <h5 className="pb-2">NRI/PIO/OCI DEFINITION AND GENERAL GUIDELINES</h5>
                                    </div>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who is a NRI?</b></p></div>
                                    <p className="pt-2">As defined by the Foreign Exchange Management Act of 1999 (FEMA), Non Resident Indian (NRI) is a citizen of India, who stays abroad for employment/carrying on business or vocation outside India or stays abroad under circumstances indicating an intention for an uncertain duration of stay abroad is a non-resident. Non-resident foreign citizens of Indian Origin are treated at par with Non Resident Indian (NRIs).</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can a NRI/PIO acquire agricultural land/plantation property/farm house in India?</b></p></div>
                                    <p className="pt-2">Since general permission is not available to NRI/PIO to acquire agricultural land/plantation property/farm house in India, such proposals will require specific approval of Reserve Bank and the proposals are considered in consultation with the Government of India</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>What mode of payment can an NRI/PIO make to purchase a residential property in India?</b></p></div>
                                    <p className="pt-2">Under the general permission, an NRI/PIO may purchase a residential property in India by funds remitted to India through normal banking channel or funds held in his NRE/FCNR (B)NRO account. No consideration shall be paid outside India.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can a NRI/PIO transfer his residential property by way of gift?</b></p></div>
                                    <p className="pt-2">Yes. An NRI/PIO can transfer their residential property in India by way of a gift to an Indian resident/NRI/PIO</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can a PIO transfer his residential property by way of sale?</b></p></div>
                                    <p className="pt-2">Yes. Under general permission an NRI can sell his or her residential property in India to an Indian resident only.
                                Can a NRI transfer his residential property by way of sale?<br /><br />Yes. Under general permission an NRI can sell his or her residential property in India to an Indian resident/NRI/PIO</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who is a PIO?</b></p></div>
                                    <p className="pt-2">Person of Indian Origin (PIO) (not being a citizen of Pakistan or Bangladesh or Sri Lanka or Afghanistan or China or Iran or Nepal or Bhutan), who
                                <br />
                                (a) at any time, held Indian passport, or
                                <br />
                                (b) who or either of whose father or whose grandfather was a citizen of India by virtue of the Constitution of India or the Citizenship Act, 1955 (57 of 1955).
                                <br />
                                Note: A spouse (not being a citizen of Pakistan or Bangladesh) of an Indian citizen or of a person of Indian origin is also treated as a person of Indian origin for the above purposes provided the bank accounts are opened or investments in shares/securities in India are made by such persons only jointly with their NRI spouses.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who is OCI?</b></p></div>
                                    <p className="pt-2">(a) Any person of full age and capacity:
                                <br />
                                (i) Who is a citizen of another country, but was a citizen of India at the time of, or at any time after, the commencement of the constitution, or (ii) Who is a citizen of another country, but was eligible to become a citizen of India at the time of the commencement of the constitution, or (iii) Who is a citizen of another country, but belongs to a territory that became part of India after the 15th Day of August, 1947. (iv) Who is a child of such a citizen, or
                                <br />
                                (b) A person, who is minor child of a person mentioned in clause<br />
                                (a) Provided that no person, who is or had been a citizen of Pakistan, Bangladesh shall be eligible for registration as an

                                Overseas Citizen of India.

                                Documents required for buying property
                                <br />
                                – Pan card (Permanent account number)
                                <br />
                                – OCI/PIO card (In case of OCI/PIO)
                                <br />
                                – Passport (In case of NRI)
                                <br />
                                – Passport size photographs
                                <br />
                                – Address proof</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who can purchase immovable property in India?</b></p></div>
                                    <p className="pt-2">Under the general permission granted by RBI, the following categories can freely purchase immovable property in India:
                                <br />
                                (i) Non-Resident Indian (NRI)- that is a citizen of India residing outside India
                                <br />
                                (ii) Person of Indian Origin (PIO)- that is an individual (not being a citizen of Pakistan or Bangladesh or Sri Lanka or Afghanistan or China or Iran or Nepal or Bhutan), who at any time, held Indian passport or
                                <br />
                                (iii) who or either of whose father or whose grandfather was a citizen of India by virtue of the Constitution of India or the Citizenship Act, 1955 (57 of 1955).
                                <br />
                                The general permission, however, covers only purchase of residential and commercial property and not for purchase of agricultural land/plantation property/farm house in India. OCI can purchase immovable property in India except agricultural land/plantation property/farmhouse.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who is a NRI?</b></p></div>
                                    <p className="pt-2">As defined by the Foreign Exchange Management Act of 1999 (FEMA), Non Resident Indian (NRI) is a citizen of India, who stays abroad for employment/carrying on business or vocation outside India or stays abroad under circumstances indicating an intention for an uncertain duration of stay abroad is a non-resident. Non-resident foreign citizens of Indian Origin are treated at par with Non Resident Indian (NRIs).</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can a NRI/PIO acquire agricultural land/plantation property/farm house in India?</b></p></div>
                                    <p className="pt-2">Since general permission is not available to NRI/PIO to acquire agricultural land/plantation property/farm house in India, such proposals will require specific approval of Reserve Bank and the proposals are considered in consultation with the Government of India</p>


                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>What mode of payment can an NRI/PIO make to purchase a residential property in India?</b></p></div>
                                    <p className="pt-2">Under the general permission, an NRI/PIO may purchase a residential property in India by funds remitted to India through normal banking channel or funds held in his NRE/FCNR (B)NRO account. No consideration shall be paid outside India.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can a NRI/PIO transfer his residential property by way of gift?</b></p></div>
                                    <p className="pt-2">Yes. An NRI/PIO can transfer their residential property in India by way of a gift to an Indian resident/NRI/PIO</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can a PIO transfer his residential property by way of sale?</b></p></div>
                                    <p className="pt-2">Yes. Under general permission an NRI can sell his or her residential property in India to an Indian resident only.

                                    Can a NRI transfer his residential property by way of sale?

Yes. Under general permission an NRI can sell his or her residential property in India to an Indian resident/NRI/PIO</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who is a PIO?</b></p></div>
                                    <p className="pt-2">Person of Indian Origin (PIO) (not being a citizen of Pakistan or Bangladesh or Sri Lanka or Afghanistan or China or Iran or Nepal or Bhutan), who

                                    (a) at any time, held Indian passport, or

                                    (b) who or either of whose father or whose grandfather was a citizen of India by virtue of the Constitution of India or the Citizenship Act, 1955 (57 of 1955).

Note: A spouse (not being a citizen of Pakistan or Bangladesh) of an Indian citizen or of a person of Indian origin is also treated as a person of Indian origin for the above purposes provided the bank accounts are opened or investments in shares/securities in India are made by such persons only jointly with their NRI spouses.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who is OCI?</b></p></div>
                                    <p className="pt-2">(a) Any person of full age and capacity:

                                    (i) Who is a citizen of another country, but was a citizen of India at the time of, or at any time after, the commencement of the constitution, or (ii) Who is a citizen of another country, but was eligible to become a citizen of India at the time of the commencement of the constitution, or (iii) Who is a citizen of another country, but belongs to a territory that became part of India after the 15th Day of August, 1947. (iv) Who is a child of such a citizen, or

                                    (b) A person, who is minor child of a person mentioned in clause (a)

                                    Provided that no person, who is or had been a citizen of Pakistan, Bangladesh shall be eligible for registration as an

                                    Overseas Citizen of India.

                                    Documents required for buying property

                                    – Pan card (Permanent account number)

                                    – OCI/PIO card (In case of OCI/PIO)

                                    – Passport (In case of NRI)

                                    – Passport size photographs

– Address proof</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who can purchase immovable property in India?</b></p></div>
                                    <p className="pt-2">Under the general permission granted by RBI, the following categories can freely purchase immovable property in India:

                                    (a) Non-Resident Indian (NRI)- that is a citizen of India residing outside India

                                    (i) Person of Indian Origin (PIO)- that is an individual (not being a citizen of Pakistan or Bangladesh or Sri Lanka or Afghanistan or China or Iran or Nepal or Bhutan), who at any time, held Indian passport or

                                    (ii) who or either of whose father or whose grandfather was a citizen of India by virtue of the Constitution of India or the Citizenship Act, 1955 (57 of 1955).

The general permission, however, covers only purchase of residential and commercial property and not for purchase of agricultural land/plantation property/farm house in India. OCI can purchase immovable property in India except agricultural land/plantation property/farmhouse.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can an NRI/foreign national acquire immovable residential property in India?</b></p></div>

                                    <p className="pt-2"><b>Acquisition by purchase: </b>Yes, under general permission granted by the RBI an NRI/PIO can purchase a residential property in India</p>

                                    <p className="pt-2"><b>Acquisition by gift: </b>Yes, under general permission granted by the RBI an NRI/PIO can acquire a residential property by way of gift from a person in India or an NRI or a PIO.</p>

                                    <p className="pt-2"><b>Acquisition by inheritance: </b>Yes, an NRI/PIO/foreign national of Indian origin can acquire residential property by way of inheritance from a person who is a resident of India as per the Provisions of Section 6(5) of the Foreign Exchange Management Act, 1999. An NRI/PIO/ foreign national of Indian origin can also acquire residential property by way of inheritance from a person who is a resident outside of India, with specific approval of Reserve Bank, provided the bequeathor had acquired such property in accordance with the provisions of foreign exchange law in force at the time of acquisition or under FEMA regulations.</p>

                                    <div className="col-md-12 text-center">
                                        <h5 className="pb-4 pt-4">TAX ON INCOME FROM PROPERTY SELLING/RENTING</h5>
                                    </div>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>What is the Tax treatment for income generated from property selling or renting for NRI/ PIO/OCI?</b></p></div>
                                    <p className="pt-2">The mere acquisition of property does not attract income tax. However, any income accruing from the ownership of it, in the form of rent (if it is let out)/annual value of the house (if is not let out and it is not the only residential property owned by that person in India) and/or capital gains (short term or long term) arising on the sale of this house or part thereof is taxable in the hands of the owner.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Do NRI/PIO/OCI have to file return in India for their property rental income and Capital Gains Tax?</b></p></div>
                                    <p className="pt-2">The Government of India has granted general permission for NRI/PIO/OCI to buy property in India and they do not have to pay any taxes even while acquiring property in India. However, taxes have to be paid if they are selling this property. Rental income earned is taxable in India, and they will have to obtain a PAN and file return of income if they have rented this property. On sale of the property, the profit on sale shall be subject to capital gains. If they have held the property for less than or equal to 3 years after taking actual possession then the gains would be short term capital gains, which are to be included in their total income as tax as per the normal slab rates shall be payable and if the property has been held for more then 3 years then the resultant gain would be long term capital gains subject to 20% tax plus applicable cess.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>How does the Double Taxation Avoidance Agreement work in the context of tax on income and Capital Gains tax paid in India by NRI?</b></p></div>
                                    <p className="pt-2">India has DTAA’s with several countries which give a favorable tax treatment in respect of certain heads of income. However, in case of sale of immovable property, the DTAA with most countries provide that the capital gains will be taxed in the country where the immovable property is situated. Hence, the non-resident will be subject to tax in India on the capital gains which arise on the sale of immovable property in India. Letting of immovable property in India would be taxed in India under most tax treaties in view of the fact that the property is situated in India.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Does Capital Gains Tax (CGT) apply to NRI/PIO/OCI ?</b></p></div>
                                    <p className="pt-2">Yes. Long-term and short-term capital gains are taxable in the hands of non-residents.</p>

                                    <div className="col-md-12 text-center">
                                        <h5 className="pb-4 pt-4">CAPITAL GAINS TAX</h5>
                                    </div>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>How is Rate of CGT computed?</b></p></div>
                                    <p className="pt-2">Type of asset: Assets like house property, land and building, jewellery, development rights etc.Rate of tax deduction at source (TDS)</p>
                                    <table className="table table-bordered pt-3 pl-4 pr-4 nri-faq">

                                        <tbody>
                                            <tr>

                                                <td>Long term</td>
                                                <td>20.6%</td>

                                            </tr>
                                            <tr>

                                                <td>Short term</td>
                                                <td>30.9%</td>

                                            </tr>

                                        </tbody>
                                    </table>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Exemption available (only for long term capital gains)</b></p></div>
                                    <p className="pt-2">The long term capital gains arising on sale of a residential house can be invested in buying/ constructing another residential house, within the prescribed time. The exemption is restricted to the amount of capital gains or amount invested in new residential house, whichever is lower. If the amount of capital gains is invested in bonds of National Highways Authority of India (NHAI) or Rural Electrification Corporation, then the entire capital gains is exempted, else the proportionate gain is exempted. As per the financial budget 2007-08, a cap of Rs. 50 lakhs has been imposed on investment that can be made in capital tax saving bonds.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>How does Double Taxation Avoidance Agreement work in the context of CGT paid in India on the foreign tax treatment?</b></p></div>
                                    <p className="pt-2">In case the non-resident pays any tax on capital gains arising in India, he would normally be able to obtain a tax credit in respect of the taxes paid in India in the home country, because the income in India would also be included in the country of tax residence. The amount of the tax credit as also the basis of computing the tax credit that can be claimed are specified in the respective country’s DTAA and is also dependent on the laws of the home country where the tax payer is a tax resident.</p>

                                    <div className="col-md-12 text-center">
                                        <h5 className="pb-4 pt-4" style={{ textTransform: "uppercase" }}>Repatriation of funds</h5>
                                    </div>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>What are the rules governing the repatriation of the proceeds of sale of immovable properties by NRI/PIO as prescribed by the Reserve Bank of India?</b></p></div>
                                    <p className="pt-2">If the property was acquired out of foreign exchange sources i.e. remitted through normal banking channels/by debit to NRE/FCNR(B) account, the amount to be repatriated should not exceed the amount paid for the property:(i) In foreign exchange received through normal banking channel or

                                    (ii) By debit to NRE account (foreign currency equivalent, as on the date of payment) or debit to FCNR(B) account.

                                    Repatriation of sale proceeds of residential property purchased by NRI’s/PIO’s out of foreign exchange is restricted to not more than two such properties. Capital gains, if any, may be credited to the NRO account from where the NRI’s/PIO’s may repatriate an account up to USD one million, per financial year, as discussed below.

If the property was acquired out of Rupee sources, NRI/PIO may remit an amount up to USD one million, per financial year, out of the balances held in the NRO account (inclusive of sale proceeds of assets acquired by way of inheritance or settlement), for all the bonafide purposes to the satisfaction of the Authorized Dealer bank and subject to tax compliance. The NRI/PIO may use this facility to remit capital gains, where the acquisition of the subject property was made by funds sourced by remittance through normal banking channels/by debit to NRE/FCNR(B) account.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can NRI / PIO repatriate outside India the sale proceeds of immovable property held in India?</b></p></div>
                                    <p className="pt-2">(a) In the event of sale of immovable property other than agricultural land / farm house / plantation property in India by a NRI / PIO, the Authorised Dealer may allow repatriation of the sale proceeds outside India, provided the following conditions are satisfied, namely:(i) the immovable property was acquired by the seller in accordance with the provisions of the foreign exchange law in force at the time of acquisition by him or the provisions of these Regulations;

                                    (ii) the amount to be repatriated does not exceed:

                                    • the amount paid for acquisition of the immovable property in foreign exchange received through normal banking channels, or

                                    • the amount paid out of funds held in Foreign Currency Non-Resident Account, or

                                    • the foreign currency equivalent (as on the date of payment) of the amount paid where such payment was made from the funds held in Non-Resident External account for acquisition of the property; and

                                    (iii) in the case of residential property, the repatriation of sale proceeds is restricted to not more than two such properties.

                                    For this purpose, repatriation outside India means the buying or drawing of foreign exchange from an authorised dealer in India and remitting it outside India through normal banking channels or crediting it to an account denominated in foreign currency or to an account in Indian currency maintained with an authorised dealer from which it can be converted in foreign currency.

                                    (b) in case the property is acquired out of Rupee resources and/or the loan is repaid by close relatives in India (as defined in Section 6 of the Companies Act, 1956), the amount can be credited to the NRO account of the NRI/PIO. The amount of capital gains, if any, arising out of sale of the property can also be credited to the NRO account.  NRI/PIO are also allowed by the Authorised Dealers to repatriate an amount up to USD 1 million per financial year out of the balance in the NRO account / sale proceeds of assets by way of purchase / the assets in India acquired by him by way of inheritance / legacy. This is subject to production of documentary evidence in support of acquisition, inheritance or legacy of assets by the remitter, and a tax clearance / no objection certificate from the Income Tax Authority for the remittance.  Remittances exceeding US $ 1,000,000 (US Dollar One million only) in any financial year requires prior permission of the Reserve Bank.

(b) A person referred to in sub-section (5) of Section 6 of the Foreign Exchange Management Act 3[3] [3], or his successor shall not, except with the prior permission of the Reserve Bank, repatriate outside India the sale proceeds of any immovable property referred to in that sub-section.</p>


                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Is the rental income from property repatriable and what are the RBI rules?</b></p></div>
                                    <p className="pt-2">The rental income, being a current account transaction, is repatriable, subject to the appropriate deduction of tax and the certification thereof by a Chartered Accountant in practice. Repatriation of sale proceeds is subject to certain conditions. The amount of repatriation cannot exceed the amount paid for acquisition of the immovable property in foreign exchange

</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>ARE ANY CONDITIONS REQUIRED TO BE FULFILLED IF REPATRIATION OF SALE PROCEEDS IS DESIRED?</b></p></div>
                                    <p className="pt-2">Applications for repatriation of sale proceeds are considered provided the sale takes place after three years from the date of final purchase deed or from the date of payment of final instalment of consideration amount, whichever is later.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>WHAT IS THE PROCEDURE FOR SEEKING SUCH REPATRIATION?</b></p></div>
                                    <p className="pt-2">Applications for necessary permission for remittance of sale proceeds should be made in form IPI 8 to the Central Office of Reserve Bank at Mumbai within 90 days of the sale of the property.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>What is POA?</b></p></div>
                                    <p className="pt-2">A Power Of Attorney (POA) or letter of attorney is a written authorization to represent or act on another’s behalf in private affairs, business, or some other legal matter, sometimes against the wishes of the other. The person authorizing the other to act is the principal, grantor, or donor (of the power).A Power Of Attorney is not an instrument of transfer in regard to any right, title or interest in an immovable property.

A Power Of Attorney, or letter of attorney, is a document that authorizes another person, known as the agent or attorney-in-fact—usually a legally competent relative or close friend over 18 years old—to handle any combination of financial, legal and health care decisions. A power of attorney is also referred to as a POA. Generally, one chooses a POA as a provision if he or she becomes incapacitated.</p>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Types of Power of Attorney</b></p></div>
                                    <p className="pt-2">A Power Of Attorney (POA) is an instrument that is used by people to confer authority on somebody else to legally act on their behalf. POA are of two types.Special Power of Attorney (SPA), while an SPA is used for transfer of a specific right to the person on whom it is conferred.

                                    General Power of Attorney (GPA), the GPA authorizes the holder to do whatever is necessary.

                                    There is no sale clause of immovable property mentioned in POA (notarized)

Registered POA from registration office allows sale clause and POA to any one</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Following are the important things to be kept in mind while executing the POA</b></p></div>
                                    <p className="pt-2">Customer Prepares POA as per defined format.Executants has to paste his/her photograph along with signature on each page.

                                    Authenticate/adjudicate the POA from Indian Embassy or local authority.

                                    Send authenticated/adjudicated POA in India.

                                    In India, the POA holder has to paste his/her photograph along with his/her left hand thumb impression and signature.

Then this document will have to be stamped for Rs. 500/- (ESBTR, Franking, Stamp paper) and notarised from a Registered Notary. Please ensure that a stamp of “Before Me” is affixed on the document.</p>

                                    <div className="col-md-12 text-center">
                                        <h5 className="pb-4 pt-4" style={{ textTransform: "uppercase" }}>NRI/PIO/OCI HOME LOANS</h5>
                                    </div>

                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Are NRI/PIO/OCI eligible for Housing loans to buy property from any Indian Bank?</b></p></div>
                                    <p className="pt-2">An authorised dealer or a housing finance institution in India approved by the National Housing Bank may provide housing loan to a non-resident Indian or a person of Indian origin residing outside India. for acquisition of a residential accommodation in India, subject to the following conditions, namely:(a) the quantum of loans, margin money and the period of repayment shall be at par with those applicable to housing finance provided to a person residing in India.

                                    (b) the loan amount shall not be credited to Non-resident External (NRE)/Foreign Currency Non-resident (FCNR)/Non-resident non-repatriable (NRNR) account of the borrower.

                                    (c) the loan shall be fully secured by equitable mortgage by deposit of title deal of the property proposed to be acquired, and if necessary, also be lien on the borrower’s other assets in India.

                                    (d) the instalment of loan, interest and other charges, if any, shall be paid by the borrower by remittances from outside India through normal banking channels or out of funds in his Non-resident External (NRE)/Foreign Currency Non-resident (FCNR)/Non-resident Non-repatriable (NRNR)/Non-resident Ordinary (NRO)/non-resident Special Rupee (NRSR) account in India, or out of rental income derived from renting out the property acquired by utilization of the loan or by any relative of the borrower in India by crediting the borrower’s loan account through the bank account of such relative (The word ‘relative’ means ‘relative’ as defined in section 6 of the Companies Act, 1956.)

(e) the rate of interest on the loan shall conform to the directives issued by the Reserve Bank of India or, as the case may be, the National Housing Bank.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can NRIs obtain loans for acquisition of a house/flat for residential purpose from authorized dealers/financial institutions providing housing finance?</b></p></div>
                                    <p className="pt-2">Reserve Bank has granted general permission to certain financial institutions providing housing finance e.g. HDFC, LIC Housing Finance Ltd., etc., and authorized dealers to grant housing loans to non-resident Indian nationals for acquisition of a house/flat for self-occupation subject to certain conditions. The purpose of the loan, margin money and the quantum of loan will be at par with those applicable to housing loans to residents. Repayment of loan should be made within a period not exceeding 15 years out of inward remittances or out of funds held in the investors’ NRE/FCNR/NRO accounts</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Can Indian companies grant loans to their NRI staff?</b></p></div>
                                    <p className="pt-2">Reserve Bank permits Indian firms/companies to grant housing loans to their employees deputed abroad and holding Indian passports subject to certain conditions.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>CAN FOREIGN CITIZENS OF INDIAN ORIGIN ACQUIRE COMMERCIAL PROPERTIES IN INDIA?</b></p></div>
                                    <p className="pt-2">Yes. Under the general permission granted by Reserve Bank properties other than agricultural land/farm house/plantation property can be acquired by foreign citizens of Indian origin provided the purchase consideration is met either out of inward remittances in foreign exchange through normal banking channels or out of funds from the purchasers non-resident (external) rupee account (NRE) or non-resident (ordinary) account (NRO) or non resident (foreign currency) account (FCNRB) accounts maintained with banks in India and a declaration is submitted to the Central Office of Reserve Bank in form IPI 7 within a period of 90 days from the date of purchase of the property/final payment of purchase consideration.</p>



                                    <div className="col-md-12 text-center">
                                        <h5 className="pb-4 pt-4" style={{ textTransform: "uppercase" }}>Income Tax</h5>
                                    </div>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Who should file tax returns?</b></p></div>
                                    <p className="pt-2">If you are an NRI/OCI/PIO, you would have to file your income tax returns if you fulfill either of these conditions:(a) Your taxable income in India during the year was above the basic exemption limit of ` 1.6 lakh OR

                                    (b) You have earned short-term or long-term capital gains from sale of any investments or assets, even if the gains are less than the basic exemption limit.

Note: The enhanced exemption limit for senior citizens and women is applicable only to residents and not to non-residents.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Are there any exceptions?</b></p></div>
                                    <p className="pt-2">es, there are two exceptions:(a) If your taxable income consisted only of investment income (interest) and/or capital gains income and if tax has been deducted at source from such income, you do not have to file your tax returns.

                                    (b) If you earned long term capital gains from the sale of equity shares or equity mutual funds, you do not have to pay any tax and therefore you do not have to include that in your tax return

                                    Tip: You may also file a tax return if you have to claim a refund. This may happen where the tax deducted at source is more than the actual tax liability. Suppose your taxable income for the year was below ` 1.6 lakh but the bank deducted tax at source on your interest amount, you can claim a refund by filing your tax return.

Another instance is when you have a capital loss that can be set-off against capital gains. Tax may have been deducted at source on the capital gains, but you can set-off (or carry forward) capital loss against the gain and lower your actual tax liability. In such cases, you would need to file a tax return.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>What’s the best way to file tax returns?</b></p></div>
                                    <p className="pt-2">Traditionally, you could file your return either by giving a power of attorney to someone in India or by sending your form and documents to a tax expert in India who would then file returns on your behalf.But nowadays, the easiest option for NRIs to file their Indian tax returns is by using the online platform. There are several options to file online.</p>
                                    <div className="nri-faq"><p className="pt-3 pl-4 pr-4"><b>Indicative list of documents required for home loans</b></p></div><br /><br />

                                    <div class="container pt-4">

                                        <table class="table table-bordered">

                                            <tbody>

                                                <tr>


                                                    <td>Salaried individuals</td>
                                                    <td>Self-employed individuals</td>


                                                </tr>
                                                <tr>

                                                    <td>Copy of employment contract</td>

                                                    <td>Balance sheets and P&L a/c of the</td>

                                                </tr>
                                                <tr>

                                                    <td>Latest Salary slip</td>

                                                    <td>company for last 3 years</td>

                                                </tr>
                                                <tr>

                                                    <td>Latest work permit</td>

                                                    <td>Bank a/c statements for last 6 months for</td>

                                                </tr>

                                                <tr>

                                                    <td>Bank statement for 4 months or NRE/NRO a/c</td>

                                                    <td>company and individual, both</td>

                                                </tr>

                                                <tr>

                                                    <td>6 months statement</td>

                                                    <td>  Income tax returns (3 years)</td>

                                                </tr>

                                                <tr>

                                                    <td>Passport/visa copy</td>

                                                    <td>Passport/visa copy</td>

                                                </tr>

                                                <tr>

                                                    <td>Utility bill for address proof</td>

                                                    <td>Utility bill for address proof</td>

                                                </tr>

                                                <tr>

                                                    <td>PIO/OCI card</td>

                                                    <td>PIO/OCI card</td>

                                                </tr>

                                                <tr>

                                                    <td>Power of Attorney (if applicable, in respective bank’s format)</td>

                                                    <td>Power of Attorney (if applicable, in respective bank’s format)</td>

                                                </tr>

                                                <tr>

                                                    <td>Customer credit check report</td>

                                                    <td>Credit check report</td>

                                                </tr>

                                                <tr>

                                                    <td>Property agreement duly registered or other related docs</td>

                                                    <td>Property agreement duly registered or other related docs</td>

                                                </tr>

                                                <tr>

                                                    <td>Income Tax returns last 2 years</td>

                                                    <td> </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row tab-1 p-4">
                                    <div className="col-md-12">
                                        <label for="Name">Your Name*</label>
                                        <input type="text" class="form-control  w-100" id="nri_name" placeholder="Your Name" name="text" />
                                        <br />

                                        <label for="email">Your Email*</label>
                                        <input type="email" class="form-control  w-100" id="nri_email" placeholder="Your email" name="email" />
                                        <br />
                                        <label for="email">Your Mobile Number*</label>
                                        <input type="Number" class="form-control  w-100" id="nri_phone" placeholder="Your Mobile Number" />
                                        <br />
                                        <button className="btn btn-dark w-50 mt-4" onClick={() => this.nriMail()}> Submit </button>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
	<script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
            </Layout >
        )
    }
}
export default mailHelper;
